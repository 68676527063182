<template>
	<div :class="myClasses" class="input input--sae mb-4">
		<label
			v-if="$slots.default"
			:for="id"
			:class="{ 'cursor-not-allowed': disabled }"
			class="input__label input__label--sae"
		>
			<span class="input__label-content input__label-content--sae">
				<slot />
			</span>
		</label>
		<div class="relative">
			<div class="input__field reset-padding input__field--sae location-autocomplete">
				<gmv-autocomplete
					:id="id"
					ref="autocomplete"
					:name="name"
					:value="internalValue"
					:disabled="disabled"
					:select-first-on-enter="true"
					:options="autocompleteOptions"
					placeholder=""
					:class="{ 'cursor-not-allowed': disabled }"
					class="p-3 outline-none w-11/12"
					@place_changed="setPlace"
					@blur="blur"
					@click="click"
					@focus="focus"
					@input="input"
					@change="change"
				/>
			</div>
			<div v-if="!disabled" class="input-icon-right" @click="resetOrLocation">
				<Spinner v-if="loading" :fixed="false" size="small" />
				<template v-else>
					<HokIcon v-if="internalValue && internalValue.length > 0" :size="5" name="icon:trash" />
					<HokIcon
						v-else-if="geoLocationAvailable && currentLocationButton"
						name="icon:pin-2"
						:size="5"
					/>
				</template>
			</div>
		</div>
		<ErrorBox v-if="requirePostal && postalIsMissing && internalValue" class="relative t-4">
			<template #title>Postleitzahl erforderlich!</template>
			Die Adresse ist zu ungenau. Bitte zusätzlich eine Postleitzahl eingeben.
		</ErrorBox>
		<HokModal
			:width="$isMobile.any ? '95%' : '350px'"
			click-to-close
			name="location-modal"
			transition="scale"
		>
			<h4 class="text-center">{{ error.title }}</h4>
			<p>{{ error.text }}</p>
			<HokButton fullwidth="always" class="mb-4" color="main" @click="closeModal"> OK </HokButton>
		</HokModal>
	</div>
</template>

<script lang="ts">
import { utilities } from '@gmap-vue/v3';
import { defineComponent } from 'vue';
import type { IPlaceResult } from './LocationAutocomplete/types';
import ErrorBox from './ErrorBox.vue';
import HokButton from './HokButton.vue';
import HokIcon from './HokIcon.vue';
import Spinner from './Spinner.vue';

function hasFocus(obj?: any): obj is { focus(): void } {
	return typeof obj?.focus === 'function';
}

function hasValue(obj?: any): obj is HTMLInputElement {
	return obj?.value !== undefined;
}

export default defineComponent({
	name: 'LocationAutocomplete',
	components: { HokButton, ErrorBox, HokIcon, Spinner },
	emits: ['click', 'blur', 'update:modelValue', 'change', 'input'],
	data() {
		const postalIsMissing = null as boolean | null;
		const { googleMapsApiInitializer } = utilities;
		const error = { title: '', text: '' };

		return {
			focused: false,
			loading: false,
			postalIsMissing,
			internalValue: this.modelValue,
			isBlurring: false,
			geoLocationAvailable: false,
			invalidValue: false,
			googleMapsApiInitializer,
			error
		};
	},
	computed: {
		myClasses() {
			const classes: string[] = [];
			if (this.focused || (this.internalValue && this.internalValue.length > 0)) {
				classes.push('input--filled');
			}
			if (this.focused) {
				classes.push('input--active');
			}
			if (this.invalidValue) {
				classes.push('has-error');
			}
			if (this.styling === 'center') {
				classes.push('mx-auto');
			}
			return classes;
		},
		autocompleteOptions() {
			// combined bounds from at, de, ch: https://gist.github.com/graydon/11198540
			const opts: {
				bounds: any;
				componentRestrictions?: { country: string };
				fields: string[];
				types?: string[];
			} = {
				bounds: {
					north: 54.983104153, // 4 >
					south: 45.7769477403, // 2 <
					west: 5.98865807458, // 1 <
					east: 16.9796667823 // 3 >
				},
				fields: ['name', 'formatted_address']
			};

			// https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
			if (this.fullResults) {
				opts.fields = ['name', 'formatted_address', 'types', 'address_components'];
			} else if (this.requirePostal && this.onlyCities) {
				opts.fields = ['name', 'formatted_address', 'address_components'];
				opts.types = ['locality', 'postal_code'];
			} else if (this.requirePostal) {
				opts.fields = ['name', 'formatted_address', 'address_components'];
				opts.types = ['geocode'];
			}

			if (this.country) {
				opts.componentRestrictions = {
					country: this.country
				};
			}

			return opts;
		},
		autocomplete() {
			return this.$refs.autocomplete as any;
		}
	},
	created() {
		if (this.id === 'locationautocomplete') {
			console.warn('LocationAutocomplete without explicit id', this);
		}
	},
	mounted() {
		this.geoLocationAvailable = typeof navigator !== 'undefined' && !!navigator.geolocation;
		if (!window.google?.maps) {
			this.googleMapsApiInitializer(this.$gmapOptions.load);
		}
	},
	methods: {
		emitChange() {
			if (hasValue(this.autocomplete?.$refs?.gmvAutoCompleteInput)) {
				if ((this.requirePostal && !this.postalIsMissing) || !this.requirePostal) {
					this.$emit(
						'update:modelValue',
						this.autocomplete?.$refs?.gmvAutoCompleteInput?.value,
						undefined,
						this.postalIsMissing
					);
				}
			}
		},
		input($event) {
			this.internalValue = $event.target.value ?? '';
			this.$emit('update:modelValue', this.internalValue, undefined, this.postalIsMissing);
		},
		change() {
			this.emitChange();
			if (hasValue(this.autocomplete?.$refs?.gmvAutoCompleteInput)) {
				if (!this.isBlurring) {
					const internalValue = this.autocomplete?.$refs?.gmvAutoCompleteInput?.value;

					this.internalValue = internalValue;

					if ((this.requirePostal && !this.postalIsMissing) || !this.requirePostal) {
						this.$emit('change', internalValue, undefined, this.postalIsMissing);
					}
				}
			}
		},
		processPlaceChange(
			placeName: string | undefined,
			place?: IPlaceResult,
			postalIsMissing?: boolean | undefined
		) {
			if (postalIsMissing !== undefined) {
				this.postalIsMissing = postalIsMissing;
			} else if (place) {
				this.setPostalIsMissing(place);
			}

			// always emmit after setting this.postalIsMissing
			this.$emit('update:modelValue', placeName, place, this.postalIsMissing);
			this.$emit('change', placeName, place, this.postalIsMissing);
		},
		setPlace(input: { formatted_address: string }) {
			// we do a seperate geo coding and do not use the _place result (this happens on do blur action)
			if (input) {
				this.internalValue = input.formatted_address;
			}
			this.doBlur();
		},
		blur($event) {
			this.isBlurring = true;
			window.setTimeout(() => {
				this.doBlur($event);
				this.isBlurring = false;
			}, 300);
		},
		async doBlur($event?: any) {
			if ($event) {
				if (!this.focused) {
					return;
				}
				this.focused = false;

				this.$emit('blur', $event);
			}
			if (this.internalValue) {
				if (this.requirePostal) {
					let result;
					try {
						this.loading = true;
						// resolve location via given function
						if (this.resolveAddress) {
							result = await this.resolveAddress({ address: this.internalValue });
							let postalIsMissing = true;
							if (result.code) {
								postalIsMissing = false;
							}
							this.processPlaceChange(this.internalValue, result, postalIsMissing);
						}
						// resolve location via api
						else {
							await (this as any).$gmapApiPromiseLazy(); // can we instead use the plugin from the website-app? (gmap-vue.client.ts)
							const geocoder = new window.google.maps.Geocoder();

							result = await new Promise((resolve, reject) => {
								geocoder.geocode(
									{
										address: this.internalValue
									},
									(geoResult, status) => {
										if (status !== 'OK') {
											// eslint-disable-next-line prefer-promise-reject-errors
											reject({ geoResult, status });
											return;
										}
										resolve(geoResult);
									}
								);
							});
							this.processPlaceChange(this.internalValue, result && result[0]);
						}
					} catch (error: any) {
						if (error.status !== 'ZERO_RESULTS') {
							throw new Error(error);
						}
					} finally {
						this.loading = false;
					}
				} else {
					this.processPlaceChange(this.internalValue, { formatted_address: this.internalValue });
				}
			}
		},
		focus() {
			this.focused = true;
		},
		click($event) {
			this.$emit('click', $event);
		},
		setGeolocation() {
			this.loading = true;
			navigator.geolocation.getCurrentPosition(
				async position => {
					if (position && position.coords) {
						try {
							const location = await this.resolveGeoCoords({
								long: position.coords.longitude,
								lat: position.coords.latitude
							});

							const { name } = location;

							if (location?.code) {
								this.postalIsMissing = false;
							}
							if (name) {
								this.processPlaceChange(name, {
									formatted_address: name,

									address_components: [
										{ types: ['country'], short_name: location.countryCode },
										{ types: ['postal_code'], short_name: location.code }
									]
								});
								this.internalValue = name;
							}
						} catch (err: any) {
							this.error = {
								title: 'Standort kann nicht aufgelöst werden',
								text: 'Bitte überprüfe die Adresse und probiere es noch einmal.'
							};
							this.$modal.show('location-modal');
						}
						this.loading = false;
					}
				},
				_err => {
					this.error = {
						title: 'Aktueller Standort nicht verfügbar',
						text: 'Bitte überprüfe in den Einstellungen ob du die Standortfreigabe aktiviert hast um die Autovervollständigung zu nutzen.'
					};
					this.$modal.show('location-modal');
					this.geoLocationAvailable = false;
					this.loading = false;
				}
			);
		},
		resetOrLocation() {
			if (this.disabled) {
				return;
			}

			this.focused = false;
			if (this.internalValue && this.internalValue.length > 0) {
				this.internalValue = '';
				this.processPlaceChange('');
			} else if (this.currentLocationButton) {
				this.setGeolocation();
			}
		},
		setPostalIsMissing(place: IPlaceResult) {
			if (this.requirePostal) {
				const hasPostal =
					place.address_components?.some(
						address => address.types.includes('postal_code') && address.short_name
					) || false;

				this.postalIsMissing = !hasPostal;
			}
		},
		changedValue(newValue) {
			this.internalValue = newValue;
		},
		closeModal() {
			this.$modal.hide('location-modal');
			if (hasFocus(this.autocomplete?.$refs?.gmvAutoCompleteInput)) {
				this.autocomplete.$refs.gmvAutoCompleteInput.focus();
			}
		}
	},
	props: {
		name: { type: String, default: '' },
		id: { type: String, default: 'locationautocomplete', required: true },
		modelValue: { type: String, default: '' },
		disabled: { type: Boolean, default: false },
		currentLocationButton: { type: Boolean },
		autofocus: { type: Boolean, default: false },
		styling: {
			type: String,
			default: 'default',
			validator: (value: string) => ['default', 'cv', 'center', 'match-overview'].includes(value)
		},
		resolveGeoCoords: { type: Function, default: null },
		resolveAddress: { type: Function, default: null },
		country: { type: String },
		fullResults: { type: Boolean, default: () => false },
		requirePostal: { type: Boolean, default: false },
		onlyCities: { type: Boolean, default: false }
	},
	watch: {
		value: [
			{
				handler: 'changedValue'
			}
		]
	}
});
</script>

<style scoped src="../styles/input.scss" />
<style lang="scss" scoped>
.location-autocomplete {
	padding-right: 2rem;
}
.input-icon-right {
	margin-left: 1rem;
	position: absolute;
	right: 0;
	z-index: 5;
	top: 50%;
	transform: translateY(-50%);
	padding: 4.2rem 0.8rem 0.8rem 0.8rem;
	svg {
		width: 1.25rem;
		height: auto;
		cursor: pointer;
	}
}
.t-4 {
	top: 1rem;
}
</style>
